import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import css from './ActiveOrder.module.css';

import {
  NamedLink,
} from '../../../components';

const ActiveOrder = props => {
  const {
    currentUserActiveBasketsDetails,
    addedToOrder
  } = props;

const addedToOrderAlert = addedToOrder ? (<div className={css.addedToOrder}>Item Added</div>) : null ;

const toLineItem =lineItem => { 

  return(
  <li key={Math.random()} >{lineItem[1]}</li>
)
  };


  if (currentUserActiveBasketsDetails) {
    
    return (
      <div className={css.activeOrder} data-cy="divSearchPageMiniBasket">
      <h2>Current Basket{addedToOrderAlert}
      
      <NamedLink
        className={css.goToOrder}
        name='OrderDetailsPage'
        params={{ id: currentUserActiveBasketsDetails.transactionId }}
      >
Go to basket >>
      </NamedLink>

      </h2>
      
      <ul className={css.lineItems}>
              {Object.entries(currentUserActiveBasketsDetails.lineItems).map(toLineItem)}
            </ul>

           
      </div>
    );
  }


  return null;

  
};

ActiveOrder.defaultProps = {
};

ActiveOrder.propTypes = {
  rootClassName: string,
  className: string,
};

export default ActiveOrder;
